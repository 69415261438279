/**
 * type 1 = majun
 * type 2 = kaos
 * type 3 = Masker
 * type 4 = Bendera
 * type 5 = sarung tangan
 * type 6 = Others
 */
export const dataProducts = [
    {
        'name' : 'Majun',
        'type' : 1,
        'image' : 'assets/img/product/majunmerah.jpeg',
        'rating' : 30
    }, 
    {
        'name' : 'Masker Kain Motif',
        'type' : 3,
        'image' : 'assets/img/product/maskermotif.jpeg',
        'rating' : 30
    }, 
    {
        'name' : 'Masker Kain Polos',
        'type' : 3,
        'image' : 'assets/img/product/maskerkainpolos.jpg',
        'rating' : 30
    }, 
    {
        'name' : 'Kaos',
        'type' : 2,
        'image' : 'assets/img/product/kaos.jpeg',
        'rating' : 40

    }, 
    {
        'name' : 'Bendera 17 Agustusan',
        'type' : 4,
        'image' : 'assets/img/product/bendera.jpeg',
        'rating' : 36
    },
    {
        'name' : 'Sarung Tangan Buldozer',
        'type' : 5,
        'image' : 'assets/img/product/sarungtanganbuldozer.jpg',
        'rating' : 45
    },
    {
        'name' : 'Karung Goni',
        'type' : 6,
        'image' : 'assets/img/product/karunggoni.jpeg',
        'rating' : 10
    }, 
    {
        'name' : 'Madu',
        'type' : 6,
        'image' : 'assets/img/product/madu.jpeg',
        'rating' : 20
    }, 
    {
        'name' : 'Sarung Tangan Plastik',
        'type' : 6,
        'image' : 'assets/img/product/sarungtanganplastik.jpeg',
        'rating' : 20
    }, 
]